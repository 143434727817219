/* VARIABLES */
:root {
  --azul_meet: #009fe3;
  --azul_meet_hover: #0883B4;
  --gris: #7489b9;
  --gris_hover: #51699E;
  --verde: #34c38f;
  --verde_hover: #299b71;
  --cian: #50C7F5;
  --cian_hover: #2BA6D3;
  --amarillo: #f1b44c;
  --amarillo_hover: #eda01d;
  --rojo: #f46a6a;
  --rojo_hover: #f03b3b;

  --azul_1: #2857b4;
  --azul_1_hover: #123B86;
  --azul_2: #5ac7e5;
  --azul_2_hover: #278BA4;
  --azul_3: #3C518F;
  --azul_3_hover: #1C3068;
  --azul_oscuro: #2a3042;
  --blanco: #fff;
  --blanco_hover: #EFEFEF;
  --negro: #333333;
  --rosado: #c92a8a;
  --verde_day: #95c11f;
  --verde_day_hover: #769819;
  --placeholder: #a5a5a5;
  --gris_claro: #9CA1AE;
  --gris_oscuro: #49505b;
  --gris_2: #aadbf0;
  --cian_claro: #C5E5F1;

  --sombra: #D6D6D6;
}

.etm_text_blue {
  color: var(--azul_meet) !important;
}

.etm_link_blue {
  color: var(--azul_meet);
}

.etm_link_blue:hover {
  color: var(--azul_meet_hover);
  text-decoration: underline;
}

.etm_text_green {
  color: var(--verde_day);
}

.etm_link_green {
  color: var(--verde_day);
}

.etm_link_green:hover {
  color: var(--verde_day_hover);
  text-decoration: underline;
}

.etm_link_gray {
  cursor: pointer;
}

.etm_link_gray:hover {
  color: var(--azul_meet);
  text-decoration: underline;
}

.etm_text_gray {
  color: var(--gris_claro);
}

.lineBlue {
  height: 5px;
  width: 100%;
  background-color: var(--azul_meet);
}

.lineGray {
  height: 5px;
  width: 90%;
  background-color: var(--gris_claro);
}

.theme_bg {
  background-color: var(--azul_oscuro);
}

.logo_breadcrumb {
  width: 30px;
}

.profile_register {
  cursor: pointer;
}

.profile_register:hover {
  color: var(--azul_meet);
}

.profile_register_active img{
  border: 5px solid;
  border-radius: 10px;
  border-color: var(--azul_meet);
}

.profile_register_active_white img{
  border: 10px solid;
  border-radius: 15px;
  border-color: var(--blanco);
}

.instagram {
  color: var(--rosado);
  cursor: pointer;
} 

.facebook {
  color: var(--azul_1_hover);
  cursor: pointer;
} 

.linkedin {
  color: var(--azul_meet_hover);
  cursor: pointer;
} 

.twitter {
  color: var(--cian);
  cursor: pointer;
} 

.youtube {
  color: var(--rojo_hover);
  cursor: pointer;
} 

.public_profile_avatar {
  height: 200px;
  width: 200px;
}

@media (max-width: 1270px) {
  .public_profile_avatar {
    height: 150px;
    width: 150px;
  }
}

.pointer {
  cursor: pointer;
}

.fa-beat {
  animation:fa-beat 5s ease infinite;
}

@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.25);
  }
  10% {
    transform:scale(1);
  }
  15% {
    transform:scale(1.25);
  }
  20% {
    transform:scale(1);
  }
  25% {
    transform:scale(1.25);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.25);
  }
  40% {
    transform:scale(1);
  }
  45% {
    transform:scale(1.25);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.25);
  }
  60% {
    transform:scale(1);
  }
  65% {
    transform:scale(1.25);
  }
  70% {
    transform:scale(1);
  }
  75% {
    transform:scale(1.25);
  }
  80% {
    transform:scale(1);
  }
  85% {
    transform:scale(1.25);
  }
  90% {
    transform:scale(1);
  }
  95% {
    transform:scale(1.25);
  }
  100% {
    transform:scale(1);
  }
  
}

.company_home {
  width: auto; 
  max-height: 50px;
  max-width: 120px;
}

@media (max-width: 1670px) {
  .company_home {
    max-width: 100px;
  }
}

@media (max-width: 1500px) {
  .company_home {
    max-width: 90px;
  }
}

@media (max-width: 1400px) {
  .company_home {
    max-width: 70px;
  }
}

@media (max-width: 1270px) {
  .company_home {
    max-width: 100px;
  }
}

.fix_img {
  object-fit: cover;
}

.modal_scrolling_div {
  max-height: 300px;
  overflow-y: auto;
}

.scrolling_div {
  height: 650px;
  overflow-y: scroll;
}

.no_scrolling_div {
  // height: 650px;
  overflow-y: hidden!important;
}

.scrolling_div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: var(--blanco);
  border-radius: 10px;
}

.scrolling_div::-webkit-scrollbar {
  width: 10px;
  background-color: var(--blanco);
}

.scrolling_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
                    left bottom,
                    left top,
                    color-stop(0.44, var(--azul_meet)),
                    color-stop(0.72, var(--azul_meet)),
                    color-stop(0.86, var(--azul_meet)));
}

.calendar_head, .calendar_body {
  display: flex;
  flex-wrap: wrap;
  width: 470px;
  margin: auto;
}

@media (max-width: 1270px) {
  .calendar_head, .calendar_body {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    margin: auto;
  }
}

.etm_video_txt_btn {
  font-size: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media (max-width: 1366px) {
  .etm_iframe_video{
    width: 88%;
    height: 600px;
  }
}

@media (max-width: 1024px) {
  .etm_iframe_video{
    width: 95%;
    height: 580px;
  }
}

@media (max-width: 768px) {
  .etm_iframe_video{
    width: 95%;
    height: 580px;
  }
}

@media (max-width: 414px) {
  .etm_iframe_video{
    width: 100%;
    height: 580px;
  }
}

.waves-effect.active span{
  background-color: var(--azul_meet);
  border-radius: 22px;
  font-size: 16px;
  font-weight: normal;
  color: var(--blanco);
  padding: 3px 10px 3px 10px;
  &:hover{
      transform: scale(1.1);
  }
}

.etm_bg_header_rol {
  background-color: var(--azul_meet);
  border-radius: 22px;
  font-size: 18px;
  color: var(--blanco);
  padding: 0px 70px 0px 70px;
}

.etm_header_centered .etm_header_logo_rol {
  position: absolute;
  height: 100px;
  top:  5px;
  left: 500px;
}

@media (max-width: 1270px) {
  .etm_header_centered .etm_header_logo_rol {
    display:none;
  }
}

.etm_meeting_summary_border {
  border: 1px solid;
  border-radius: 25px;
  border-color: var(--gris_claro);
}

@media (max-width: 1024px) {
  .etm_meeting_summary{
    margin-top: -50px;
  }
}

.etm_box_summary_footer{
  margin-top: -15px;
}

.etm_box_summary {
  width: 100px;
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .etm_box_summary{
    width: auto;
    padding: 0px;
    margin-top: -5px;
  }
  .etm_box_summary .card-body{
    margin-bottom: -15px;
  }
  .etm_box_summary h4{
    margin-top: -15px;
  }
}

@media (max-width: 992px) {
.etm_box_summary_footer{
    margin-top: -20px;
    padding-bottom: -20px;
  }
}

.etm_bg_next_meeting {
  background-color: var(--blanco);
  border-radius: 10px;
}

.etm_search_border {
  border: 1px solid;
  border-radius: 25px;
  border-color: var(--gris_claro);
  background-color: var(--blanco);
}

.etm_star_etm_avatar_sponsor_blue {
  position: absolute;
  left: 87%;
  top: 5%;
  font-size: 22px;
}

@media(max-width: 1366px){
  .etm_star_etm_avatar_sponsor_blue {
    left: 85%;
    top: 5%;
  }
}

@media(max-width: 400px){
  .etm_star_etm_avatar_sponsor_blue {
    left: 80%;
    top: 5%;
  }
}

.etm_card {
  background-color: var(--blanco);
  border: 4px solid;
  border-color: var(--sombra);
  border-radius: 25px;
}

.etm_card_sponsor {
  background-color: var(--blanco);
  border: 4px solid;
  border-color: var(--azul_meet);
  border-radius: 25px;
}

.etm_star_avatar_sponsor {
  position: absolute;
  left: 87%;
  top: 5%;
  font-size: 22px;
}

@media(max-width: 1366px){
  .etm_star_avatar_sponsor {
    left: 85%;
    top: 5%;
  }
}

@media(max-width: 400px){
  .etm_star_avatar_sponsor {
    left: 80%;
    top: 5%;
  }
}

.etm_badge_blue {
  background-color: var(--azul_meet);
  border-radius: 22px;
  font-size: 12px;
  color: var(--blanco);
  padding: 0px 10px 0px 10px;
}

.etm_badge_blue_outline {
  border: 1px solid;
  border-color: var(--azul_meet);
  border-radius: 22px;
  font-size: 12px;
  padding: 0px 10px 0px 10px;
}

.etm_public_profile_logo {
  width: 350px; 
  height: auto;
}

.etm_public_profile_company_logo {
  width: auto; 
  max-height: 70px;
  max-width: 120px;
}

@media (max-width: 1670px) {
  .etm_public_profile_company_logo {
    width: auto; 
    max-height: 50px;
    max-width: 100px;
  }
}

@media (max-width: 1500px) {
  .etm_public_profile_company_logo {
    width: auto; 
    max-height: 50px;
    max-width:90px;
  }
}

@media (max-width: 1400px) {
  .etm_public_profile_company_logo {
    width: auto; 
    max-height: 50px;
    max-width:70px;
  }
}

@media (max-width: 1270px) {
  .etm_public_profile_company_logo {
    width: auto; 
    max-height: 50px;
    max-width: 100px;
  }
}

.etm_public_profile_indicators {
  border: 1px solid;
  border-radius: 25px;
  border-color: var(--gris_claro);
}

.etm_public_profile_box_blue {
  background-color: var(--azul_1) !important;
  width: 100px;
  padding: 10px;
}

.etm_public_profile_box_cian {
  background-color: var(--azul_meet_hover) !important;
  width: 100px;
  padding: 10px;
}

.etm_public_profile_footer {
  background-color: var(--azul_oscuro);
  color: var(--gris_claro);
  padding-top: 15px;
  padding-bottom: 15px;
}

.etm_public_profile_bg {
  background-color: rgba(197, 198, 200, 0.4) ;
}

.etm_public_profile_profile_border {
  border-style: solid;
  border-width: solid;
  border-radius: 30px 30px 30px 30px !important;
}

.etm_public_profile_body {
  background-color: rgba(0, 0, 0, 0.4) !important;
  overflow-x: hidden;
}

.etm_public_profile_bg_logo {
  background-color: var(--azul_meet) !important;
}


.etm_admin_avatar {
  height: 70px;
  width: 70px;
}

.etm_admin_company_logo {
  width: auto; 
  max-height: 30px;
  max-width: 100px;
}

@media (max-width: 1670px) {
  .etm_admin_company_logo {
    width: auto; 
    max-height: 50px;
    max-width: 100px;
  }
}

.etm_waiting_room_avatar {
  height: 90px;
  width: 90px;
}

.etm_confirmed_line_blue {
  height: 1px;
  width: 60%;
  text-align: center;
  background-color: var(--azul_meet);
  margin-top: -1px;
}

@media (max-width: 1670px) {
  .etm_confirmed_line_blue {
    width: 100%;
  }
}

.etm_confirmed_line_gray {
  height: 1px;
  width: 60%;
  text-align: center;
  background-color: var(--gris_claro);
  margin-top: -1px;
}

@media (max-width: 1670px) {
  .etm_confirmed_line_gray {
    width: 100%;
  }
}

.etm_register_logo_event{
  width: 40%;
}

@media (max-width: 1270px) {
  .etm_register_logo_event {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .etm_register_logo_event {
    width: 75%;
  }
}

.etm_card_export .card{
  height: 230px;
}

.etm_public_banner_logo {
  width: 180px; 
  height: auto;
}

.etm_banner_title {
  color: var(--gris_oscuro);
}

.etm_banner_text_1 {
  font-size: 12px;
}

.etm_banner_text_2 {
  font-size: 10px;
}


.etm_startups_list_img {
  max-width: 200px;
  height: auto;
}

.etm_btn_meet_mobile {
  position: relative;
  top: 10px;
  z-index: 1051;
}

.etm_policies_terms h1{
  font-size: 50px;
}

.etm_policies_terms h3{
  font-size: 25px;
}

.etm_policies_terms p{
  font-size: 16px;
}

.etm_policies_terms li{
  font-size: 16px;
}

.etm_checkbox {
  width: 2.5vw;
  height: 2.5vh;
}

.etm_div_datatable {
  padding-bottom: 10rem;
}

@media(max-width: 768px){
  .etm_div_datatable {
    padding-bottom: 8rem;
  }
}

@media(max-width: 1024px){
  .etm_div_datatable {
    padding-bottom: 8rem;
  }
}

@media(max-width: 400px){
  .etm_div_datatable {
    padding-bottom: 6rem;
  }
}

.etm_donation_paycard {
  background-color:var(--blanco);
  border-radius: 10px;
  border: 1px solid var(--gris_claro);
  cursor: pointer;
  font-size: 20px;
}

.etm_donation_paycard_active {
  background-color:var(--azul_meet);
  color: var(--blanco);
}

.my_profile_profile_nav {
  background-color: var(--gris_claro);
  cursor: pointer;
}

.my_profile_profile_nav.nav_left {
  border-radius: 50px 0px 0px 50px;
}

.my_profile_profile_nav.nav_right {
  border-radius: 0px 50px 50px 0px;
}

.my_profile_profile_nav_badge {
  color: var(--blanco);
  padding: 13px 20px 13px 20px;
  border-radius: 100px;
  background-color: var(--gris);
  font-size: 16px;
  border: 5px solid var(--blanco) !important;
}

.my_profile_profile_nav_badge:hover {
  background-color: var(--gris_hover);
}

.my_profile_profile_nav_badge.active {
  background-color: var(--azul_meet);
  padding: 13px 20px 13px 20px;
}

.my_profile_profile_nav_badge.active:hover {
  background-color: var(--azul_meet_hover);
}



.etm_calendar_btn {
  border-color: var(--azul_meet) !important;
  color: var(--azul_meet) !important;
}

.etm_calendar_btn:hover {
  background: var(--azul_meet);
  border-color: var(--azul_meet);
  color: var(--blanco) !important;
}

.etm_calendar_day {
  width: 65px;
  height: 65px;
  margin: 1px;
  color: var(--azul_meet_hover);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

@media (max-width: 1270px) {
  .etm_calendar_day {
    width: 40px;
    height: 40px;
    margin: 1px;
    color: var(--azul_1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.etm_calendar_day.inactive {
  color:var(--gris_claro);
}

.etm_calendar_day.inactive:hover {  
  cursor: not-allowed !important;
}

.etm_calendar_day.available {
  cursor: pointer;
  background: var(--cian_claro);
  color: var(--blanco);
  border-radius: 50%;
  font-size: 26px;
}

.etm_calendar_day.available:hover {
  cursor: pointer !important;
}

.etm_calendar_day:hover {
  cursor: pointer;
  background: var(--cian);
  color: var(--blanco);
  border-radius: 50%;
  font-size: 26px;
}

.etm_calendar_day.selected {
  background: var(--azul_meet);
  color: var(--blanco);
  border-radius: 50%;
  font-size: 26px;
}

.etm_calendar_day.selected:hover {
  cursor: pointer !important;
}

// .etm_calendar_day.invalid_selected {
//   background: var(--rojo);
//   color: var(--blanco);
//   border-radius: 50%;
//   font-size: 20px;
// }

.etm_calendar_head_item {
  font-size: 17px;
  color: var(--azul_meet);
}

.etm_calendar_strike_text {
  // text-decoration: line-through;
  // text-decoration-color: var(--rojo);
  // text-decoration-thickness: 0.2em;
  // font-size: 12px;
  color: var(--rojo);
  font-size: 12px;
}

.etm_videocall_frame {
  top: 0;
  left: 0;
  position: fixed !important;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-y: auto !important;
}

.etm_iframe_video{
  border: 1px solid var(--gris_oscuro);
  overflow-y: auto;
  resize: both;
  width: 90vw;
  height: 70vh;
}

@media(max-width: 1024px) {
  .etm_iframe_video{
    height: 60vh;
  }
}

@media(max-width: 768px) {
  .etm_iframe_video{
    height: 70vh;
  }
}

@media(max-width: 500px) {
  .etm_iframe_video{
    height: 75vh;
  }
}

@media(max-width: 400px) {
  .etm_iframe_video{
    height: 70vh;
  }
}

.etm_popups_meet_now {
  position: fixed;
  right: 2%;
  bottom: 3%;
  border-style: solid !important;
  border-width: solid !important;
  border-color: var(--azul_meet) !important;
  border-radius: 30px 30px 0px 30px;
  background-clip: padding-box;
  width: 200px;
  padding: 20px;
  text-align: center;
  background-color: var(--blanco);
  font-size: 16px;
}

.etm_input_text {
  border: none !important;
  border-radius: 0px !important;
  border-bottom:1px solid var(--azul_meet) !important;
}

.etm_input_text::placeholder {
  color: var(--placeholder) !important;
}

.etm_input_select {
  border: none !important;
  border-radius: 0px !important;
  border-bottom:1px solid var(--azul_meet) !important;
  background: transparent !important;
  outline: none !important;
}

.etm_input_group {
  border: none !important;
  border-radius: 0px !important;
  border-bottom:1px solid var(--azul_meet) !important;
  background: transparent !important;
  outline: none !important;
}

.etm_textarea {
  border-color: var(--azul_meet) !important;
}

.etm_input_phone {
  color: var(--gris) !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom:1px solid var(--azul_meet) !important;
  background: transparent;
  outline: none !important;
  width: 100% !important;
}

.etm_input_phone_flag {
  color: var(--gris) !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom:1px solid var(--azul_meet) !important;
  background: transparent !important;
  outline: none !important;
}

.etm_disabled {
  border: none !important;
  border-radius: 0px !important;
  border-bottom:1px solid var(--azul_meet) !important;
  background: var(--gris_claro) !important;
  outline: none !important;
}

.etm_box_blue {
  background-color: var(--azul_1) !important;
}

.etm_box_blue:hover {
  background-color: var(--azul_1_hover) !important;
}

.etm_box_blue_2 {
  background-color: var(--azul_meet) !important;
}

.etm_box_blue_2:hover {
  background-color: var(--azul_meet_hover) !important;
}

.etm_box_blue_3 {
  background-color: var(--azul_2) !important;
}

.etm_box_blue_3:hover {
  background-color: var(--azul_2_hover) !important;
}

.etm_box_blue_4 {
  background-color: var(--azul_3) !important;
}

.etm_box_blue_4:hover {
  background-color: var(--azul_3_hover) !important;
}

.etm_next_meet {
  border: 1px solid !important;
  border-color: var(--blanco) !important;
}

.etm_next_meet:hover {
  border: 1px solid !important;
  border-color: var(--azul_meet) !important;
}

.etm_modal_header {
  background-color: var(--azul_oscuro);
  left: 50% !important;
  padding: 10px !important;
  text-align: center !important;
}


.etm_modal_etmday {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("../images/etmday/header.jpg");
}

.etm_modal_etmday .title_etmday{
  font-size: 33px;
  font-weight: 600;
  line-height : 32px;
}

.etm_modal_etmday .subtitle_etmday{
  font-size: 25px;
  line-height : 30px;
}

.etm_modal_etmday .btn_etmday{
  background-color: var(--verde_day);
  font-size: 20px;
}

.etm_modal_etmday .btn_etmday:hover{
  background-color: var(--verde_day_hover);
}

.etm_modal_etmday .footer_etmday{
  font-size: 17px;
  line-height: 20px;
  opacity: 0.8;
}

.etm_modal_header_close_solicitud_lg {
  color: var(--blanco);
  position: relative;
  left: 70%;
}

.etm_modal_header_close_solicitud_lg:hover {
  color: var(--azul_meet);
}

.etm_modal_header_close_solicitud_xl {
  color: var(--blanco);
  position: relative;
  left: 160%;
}

.etm_modal_header_close_solicitud_xl:hover {
  color: var(--azul_meet);
}

.etm_modal_header_close_startup {
  position: relative;
  left: 400%;
}

.etm_modal_header_close_startup:hover {
  color: var(--azul_meet);
}

@media (max-width: 1270px) {
  .etm_modal_header_close_solicitud_lg {
    display: none;
  }
  .etm_modal_header_close_solicitud_xl {
    display: none;
  }
  .etm_modal_header_close_startup {
    display: none;
  }
}

.etm_modal_close:hover {
  color: var(--azul_meet);
}

@media(max-width:768px){
  .etm_modal_etmday .title_etmday{
    font-size: 28px;
  }
  .etm_modal_etmday .subtitle_etmday{
    font-size: 24px;
  }
  .etm_modal_etmday .btn_etmday{
    font-size: 22px;
    line-height: 21px;
  }
  .etm_modal_etmday .logo_etmday{
    width: 200px !important;
  }
}

@media(max-width:450px){
  .etm_modal_etmday .subtitle_etmday{
    font-size: 22px;
  }
  .etm_modal_etmday .logo_etmday{
    width: 160px !important;
  }
  .etm_modal_etmday .btn_etmday{
    font-size: 20px;
    line-height: 18px;
  }
}

.etm_avatar_sponsor_blue {
  border: 3px solid var(--azul_meet) !important;
}

.etm_avatar_menu_mobile {
  display: none;
}

@media (max-width: 992px) {
  .etm_avatar_menu_mobile {
    display: inline;
  }
}

.admin_anfitriones_active {
  border: 2px solid !important;
  border-color: var(--azul_meet) !important;
}

.admin_dias_active {
  border: 2px solid !important;
  border-color: var(--azul_meet) !important;
}

.etm_admin_header_collapse:hover {
  background-color: var(--azul_meet) !important;
}

.etm_admin_filter_active {
  background-color: var(--azul_meet) !important;
  color: var(--blanco) !important;
}

.etm_admin_emprendedores {
  border: 1px solid !important;
  border-color: var(--gris) !important;
}

.etm_card_next_meet:hover {
  border: 1px solid !important;
  border-color: var(--azul_meet) !important;
}

.etm_video_permisos_camera_micro{
  width: 50%;
  border: 3px solid;
  border-radius: 10px;
  border-color: var(--azul_meet_hover);
}

@media (max-width: 1024px) {
  .etm_video_permisos_camera_micro {
    width: 100%;
    border: 3px solid;
    border-radius: 10px;
    border-color: var(--azul_meet_hover) !important;
  }
}

.etm_empty_input {
  border: 1px solid var(--rojo) !important;
}

.etm_img_powered_ey {
  margin-top: -30px;
  height: 45px;
  max-height: 45px;
  width: auto;
}

.etm_dashboard_img_sponsor{
  height: 70px;
  max-height: 70px;
  width: auto;
}

.etm_bg_blue {
  background-color: var(--azul_meet) !important;
}

.etm_bg_blue1 {
  background-color: var(--azul_1) !important;
}

.etm_bg_blue2 {
  background-color: var(--azul_2) !important;
}

.etm_bg_blue3 {
  background-color: var(--azul_oscuro) !important;
}

.etm_home_challenge_img {
  width: 100%;
  max-width: 80px;
  height: auto;
  max-height: 150px;
}

.etm_next_meeting_bg {
  background-color: var(--gris_claro);
  border-radius: 10px;
}

.etm_next_meeting_picture {
  z-index:1;
  float: left;
}

//  BOTONES

.etm_btn {
  border-radius: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border: none !important;
}

.etm_btn_outline {
  border-radius: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.btn-primary {
  background-color: var(--azul_meet) !important;
  border: 1px solid var(--azul_meet) !important;
}

.btn-primary:hover {
  background-color: var(--azul_meet_hover) !important;
}

.btn-outline-primary {
  border: 1px solid var(--azul_meet) !important;
  color: var(--azul_meet) !important;
}

.btn-outline-primary:hover {
  background-color: var(--azul_meet) !important;
  color: var(--blanco) !important;
}

.btn-secondary {
  background-color: var(--gris) !important;
  border: 1px solid var(--gris) !important;
}

.btn-secondary:hover {
  background-color: var(--gris_hover) !important;
}

.btn-outline-secondary {
  border: 1px solid var(--gris) !important;
  color: var(--gris) !important;
}

.btn-outline-secondary:hover {
  background-color: var(--gris) !important;
  color: var(--blanco) !important;
}

.etm_modal_solicitar_border_left {
  border-left: solid 1px var(--gris_claro);
}

.etm_modal_solicitar_hora_border {
  border: solid 1px var(--gris_claro);
  border-radius: 10px;
  cursor: pointer;
}

.etm_modal_solicitar_hora_border:hover {
  background-color: var(--cian);
}

.etm_modal_solicitar_hora_border.disabled:hover {
  background-color: var(--gris_claro);
}

.etm_modal_solicitar_hora_border:hover > label{
  color: var(--blanco);
}

.etm_modal_solicitar_hora_border.active{
  background-color: var(--azul_meet);
  border: solid 1px var(--azul_meet);
}

.etm_modal_solicitar_hora_border.disabled{
  background-color: var(--gris_claro);
  border: solid 1px var(--azul_meet);
}

.etm_modal_solicitar_hora_border.active label{
  color: var(--blanco);
}

.etm_modal_solicitar_hora_border.active i{
  color: var(--blanco) !important;
}

.etm_profile_startups_img {
  width: 180px;
  max-width: 180px;
  height: auto;
}

.etm_lockscreen {
  position: fixed;
  left: 0px;
  top: -100px;
  width: 100%;
  height: 120vh !important;
  z-index: 9999;
  background: no-repeat rgb(17, 17, 17);
  opacity: 0.85;
}

.etm_lockscreen > img {
  margin-top: 20%;
  opacity: 1 !important;
}

@media(max-width: 1024px){
  .etm_lockscreen > img {
    margin-top: 30%;
  }
}

@media(max-width: 768px){
  .etm_lockscreen > img {
    margin-top: 50%;
  }
}

@media(max-width: 414px){
  .etm_lockscreen > img {
    margin-top: 80%;
  }
}

.etm_img_border {
  border-radius: 8px;
}

.etm_sombra {  
  box-shadow: 3px 3px 3px 3px var(--sombra);
  transition: box-shadow 0.3s;
}

.styles-module_image__2hdkJ{
  z-index: 999999 !important;
}

.etm_datatable_header {
  background-color: var(--gris_hover);
  color: var(--blanco);
  font-size: medium;
}

.text_muted {
  color: #8492a6 !important;
}

.etm_center {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 200px;
}

.banner-header > a {
  position: absolute;
}

@media(max-width: 780px){
  .banner-header > a {
    display: none;
    background-color: #fa2104;
  }
}


/************************
*** NO CAMBIAR CLASES *** 
************************/

// .video-background-holder {
//   position: relative;
//   background-color: var(--negro);
//   height: calc(100vh - 72px);
//   height: 100%;
//   min-height: 25rem;
//   width: 100%;
//   overflow: hidden;
// }

// .video-background-holder video {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   min-width: 100%;
//   min-height: 100%;
//   width: auto;
//   height: auto;
//   z-index: 0;
//   -ms-transform: translateX(-50%) translateY(-50%);
//   -moz-transform: translateX(-50%) translateY(-50%);
//   -webkit-transform: translateX(-50%) translateY(-50%);
//   transform: translateX(-50%) translateY(-50%);
// }

// .video-background-content {
//   position: relative;
//   z-index: 2;
// }

// .video-background-overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   background-color: var(--negro);
//   opacity: 0.5;
//   z-index: 1;
// }

.animate__animated, .animate__fadeIn {
  z-index: 0 !important;
}

.css-26l3qy-menu {
  // z-index: 9999 !important;
}

.css-2b097c-container, .css-1yb2wzy-control, .css-1hb7zxy-IndicatorsContainer, .css-tlfecz-indicatorContainer {
  // background: transparent !important;
  // z-index: 9999 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--azul_meet) !important;
  border-color: darken(#009fe3, 10%) !important;
}

#step_arrow {
  padding: 0;
  list-style-type: none;
  font-size: 12px;
  line-height: 1em;
}

.step_arrow_element_large {
  display: inline;
}

.step_arrow_element_short {
  display: none;
}

#step_arrow li {
  float: left;
  padding: 10px 20px 10px 40px;
  background: var(--blanco);
  position: relative;
  border-top: 1px solid var(--gris_2);
  border-bottom: 1px solid var(--gris_2);
  width: 33%;
  margin: 0 1px;
  font-size: 14px;
}

@media (max-width: 1270px) {
  .step_arrow_element_large {
    display: none;
  }
  .step_arrow_element_short {
    display: inline;
  }
  #step_arrow li {
    width: 30%;
  }
}

#step_arrow li:before {
  content: '';
  border-left: 16px solid var(--blanco);
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

#step_arrow li:after {
  content: '';
  border-left: 16px solid var(--blanco);
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

#step_arrow li.active {
  background: var(--azul_meet);
  border-top: 1px solid var(--azul_meet);
  border-bottom: 1px solid var(--azul_meet);
  color: var(--blanco);
}

#step_arrow li.active:after {
  border-left-color: var(--azul_meet);
}

#step_arrow li.inactive {
  background: var(--gris_2);
  color: var(--blanco);
}

#step_arrow li.inactive:after {
  border-left-color:var(--gris_2);
}

.custom-switch.custom-switch-lg {
  padding-bottom: 1rem;
  padding-left: 2.25rem;

  & .custom-control-label {
    padding-left: .75rem;
    padding-top: 0.15rem;

    &::before {
      border-radius: 1rem;
      height: 1.5rem;
      width: 2.5rem;
    }

    &::after {
      border-radius: .65rem;
      height: calc(1.5rem - 4px);
      width: calc(1.5rem - 4px);
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after{
    transform: translateX(1rem);
  }
}

.custom-switch.custom-switch-xl {
  padding-bottom: 1rem;
  padding-left: 2.25rem;

  & .custom-control-label {
    padding-left: 2rem;
    padding-top: 0.5rem;

    &::before {
      border-radius: 1rem;
      height: 2rem;
      width: 3.5rem;
    }

    &::after {
      border-radius: 2rem;
      height: calc(2rem - 4px);
      width: calc(2rem - 4px);
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.5rem);
  }
}

.dashboard_sponsor .scroll_table_gerencias::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
.dashboard_sponsor .scroll_table_gerencias::-webkit-scrollbar-thumb {
  background: var(--amarillo);
  border-radius: 10px;
}

/* Handle on hover */
.dashboard_sponsor .scroll_table_gerencias::-webkit-scrollbar-thumb:hover {
  background: var(--amarillo); 
}

.dashboard_sponsor .scroll_table_usuarios::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
.dashboard_sponsor .scroll_table_usuarios::-webkit-scrollbar-thumb {
  background: var(--rojo);
  border-radius: 10px;
}

/* Handle on hover */
.dashboard_sponsor .scroll_table_usuarios::-webkit-scrollbar-thumb:hover {
  background: var(--rojo); 
}

.dashboard_sponsor .scroll_table_regiones::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
.dashboard_sponsor .scroll_table_regiones::-webkit-scrollbar-thumb {
  background: var(--rojo);
  border-radius: 10px;
}

/* Handle on hover */
.dashboard_sponsor .scroll_table_regiones::-webkit-scrollbar-thumb:hover {
  background: var(--rojo); 
}

.waves-effect {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.waves-effect:hover span{
  color: var(--azul_meet) !important;
}

.waves-effect:hover i{
  color: var(--azul_meet) !important;
}

.waves-effect.active:hover span{
  color: var(--blanco) !important;
}

.waves-effect.active:hover i{
  color: var(--blanco) !important;
}

.waves-effect.active span i{
  color: var(--blanco) !important;
}

.waves-effect.red_menu:hover span {
  color: var(--rojo) !important;
}

.waves-effect.red_menu:hover i {
  color: var(--rojo) !important;
}

//  SWAL
.swal-button {
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
}

//  SIDEBAR MENU
.vertical-menu {
  border-radius: 0px 40px 0px 0px !important;
  background-image: url("../images/conecTime/design/Fondo-blanco-1.png") !important;
  z-index: 1050 !important;
}

#page-topbar{
  padding: 0px 0px 35px 0px !important;
}

@media (max-width: 1270px) {
  #page-topbar{
    padding: 0px 0px 0px 0px !important;
  }
}

.main-content {
  margin-top: 40px !important;
}

/****************************
*** FIN NO CAMBIAR CLASES *** 
****************************/