/* VARIABLES */
:root {
  --negro: #009fe300;
  --negro_rgba: rgba(0, 0, 0, 1);
  --gris_claro: #d3d3d3;
  --gris_claro_rgba: 211, 211, 211;
  --gris_menu: #808080;
  --gris_medio: #727070;
  --gris_oscuro: #3d3d3b;
  --gris_oscuro_rgba: 60, 61, 59;
  --azul: #1b9dd9;
  --azul_hover: #009FE3;
  --azul_rgba: rgba(27, 150, 210, 0.7);
  --blanco: #fff;
  --blanco_rgba: 255, 255, 255;
  --cafe_claro: #acacac;
  --cafe_medio: #818181;
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Book'), url('../fonts/etm/Campton-Book.otf') format('truetype');
}

@font-face {
  font-family: 'CamptonBold';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Bold'), url('../fonts/etm/Campton-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'CamptonLight';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Light'), url('../fonts/etm/Campton-Light.otf') format('truetype');
}

body {
  overflow-x: hidden;
  overflow-y: overlay;
}

::-webkit-scrollbar {
  width: 13px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--azul);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--azul);
}

// NAV

.navbar {
  background: var(--negro_rgba);
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.navbar .nav_mobile_menu {
  display: none;
}

@media (max-width: 991px) {
  .navbar .nav_mobile_menu {
    display: inline;
  }
  .navbar .nav_mobile_menu .nav-link{
    color: var(--gris_menu);
  }
}

.navbar_logo_etm {
  width: 30%;
}

@media (max-width: 1200px) {
  .navbar_logo_etm {
    width: 35%;
  }
}

@media (max-width: 1024px) {
  .navbar_logo_etm {
    width: 30%;
  }
}

@media (max-width: 991px) {
  .navbar_logo_etm {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .navbar_logo_etm {
    width: 55%;
  }
}

@media (max-width: 575px) {
  .navbar_logo_etm {
    width: 200%;
  }
}

@media (max-width: 414px) {
  .navbar_logo_etm {
    width: 300%;
  }
}

@media (max-width: 375px) {
  .navbar_logo_etm {
    width: 350%;
  }
}

.navbar_bar_icon {
  color: var(--gris_medio);
}

.navbar_bar_icon:hover {
  color: var(--gris_claro);
}

//  MENU

.menu_landing {
  background-color: rgba(var(--gris_oscuro_rgba), 1);
  position: fixed;
  padding-bottom: 100%;
  right: 0%;
  top: 0%;
  z-index: 2 !important;
  font-size: 25px;
  font-weight: 999;
  opacity: 0.9 !important;
}

@media (max-width: 767px){
  .menu_landing {
    padding-bottom: 150%;
    right: 0%;
    width: 80%;
  }
}

.menu_landing .menu_landing_option {
 cursor: pointer;
}

.menu_landing .menu_landing_option:hover {
  background-color: var(--azul);
}

.menu_landing a {
  color: var(--blanco);
}

.menu_landing a:hover {
  color: var(--blanco);
}

.menu_landing .menu_landing_active {
  width: 100%;
  background-color: var(--azul);
}

// HEADER

.header_title {
  height: 50vh;
  width: auto;
  background-color: rgba(var(--gris_claro_rgba), 0.6);
  background-image: url("../images/etmmeet/landing/header_title.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (max-width: 767px) {
  .header_title {
    height: 100vh;
  }
}

.header_title .header_txt_title{
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
}

.header_title .header_txt_subtitle{
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: var(--gris_oscuro);
}

.header_title .header_btn{
  font-family:'Campton', Arial, Helvetica, sans-serif;
  font-size: 22px;
  padding: 5px 100px 5px 100px;
}

.header_title .header_img {
  width: 300px;
  height: auto;
}

@media (max-width: 768px) {
  .header_title .header_img {
    width: 250px;
    height: auto;
  }
}

@media (max-width: 414px) {
  .header_title .header_img {
    width: 230px;
    height: auto;
  }
}

// HEADER PROFILE

.profile {
  height: 50vh;
  width: auto;
  background-color: var(--azul); 
}

@media (max-width: 767px) {
  .profile {
    height: 100vh;
  }
}

.profile .profile_border_right {
  border-right: solid 1px var(--blanco);
}

.profile .profile_border_left {
  border-left: solid 1px var(--blanco);
}

.profile .profile_img {
  width: 250px;
  height: auto;
}

@media (max-width: 768px) {
  .profile .profile_img {
    width: 250px;
    height: auto;
  } 
}

@media (max-width: 768px) {
  .profile .profile_img {
    width: 250px;
    height: auto;
  } 
}

@media (max-width: 414px) {
  .profile .profile_img {
    width: 230px;
    height: auto;
  } 
}

.profile .profile_text_onimage {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-5%, -60%);
}

.profile .profile_btn_onimage {
  position: absolute;
  top: 80%;
  left: 45%;
  transform: translate(-5%, -60%);
  background-color: rgba(var(--gris_claro_rgba), 1);
  color: var(--azul);
  font-size: 18px;
  font-weight: 900;
  z-index: 9;
}

@media (max-width: 768px) {
  .profile .profile_text_onimage {
    font-size: 18px;
  }
  .profile .profile_btn_onimage {
    top: 85%;
    left: 40%;
    transform: translate(-5%, -60%);
  }
}

@media (max-width: 375px) {
  .profile .profile_btn_onimage {
    top: 85%;
    left: 37%;
    transform: translate(-5%, -60%);
  }
}

.profile .profile_transparent {
  opacity: 0.09;
}

// TESTIMONIAL

.bg_testimonials_aboutus {
  width: auto;
  background-color: rgba(var(--gris_claro_rgba), 0.6);
  background-image: url("../images/etmmeet/landing/testimonials.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.testimonials {
  width: auto;
}

.testimonials .testimonials_title_xl {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
  font-size: 54px;
}

@media (max-width: 991px) {
  .testimonials .testimonials_title_xl {
    font-size: 48px;
  }  
}

@media (max-width: 768px) {
  .testimonials .testimonials_title_xl {
    font-size: 44px;
  }  
}

@media (max-width: 414px) {
  .testimonials .testimonials_title_xl {
    font-size: 36 px;
  }  
}

.testimonials .testimonials_title_slider {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
}

.testimonials .testimonials_subtitle_slider {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  color: var(--gris_oscuro);
}

.testimonials .testimonials_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
  font-size: 56px;
}

.testimonials .testimonials_subtitle {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  color: var(--gris_oscuro);
  font-size: 16px;
}

@media (max-width: 1200px){
  .testimonials .testimonials_title {
    font-size: 48px;
  }
  
  .testimonials .testimonials_subtitle {
    font-size: 15px;
  }
}

@media (max-width: 991px){
  .testimonials .testimonials_title {
    font-size: 44px;
  }
  
  .testimonials .testimonials_subtitle {
    font-size: 15px;
  }
}

@media (max-width: 767px){
  .testimonials .testimonials_title {
    font-size: 56px;
  }
  
  .testimonials .testimonials_subtitle {
    font-size: 16px;
  }
}

.testimonials .testimonials_hr_left {
  height: 1px;
  background-color: var(--gris_medio);
}

.testimonials .testimonials_between_line {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 30px;
}

@media (max-width: 1300px){
  .testimonials .testimonials_between_line {
    font-size: 24px;
  }
}

@media (max-width: 991px){
  .testimonials .testimonials_between_line {
    font-size: 20px;
    margin-left: 20px;
  }
}

.testimonials .testimonials_between_line::before, .testimonials_between_line::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--gris_medio);
  margin-right: 300px;
  margin-left: 300px;
}

.testimonials .testimonials_between_line:not(:empty)::before {
  margin-right: 2em;
}

.testimonials .testimonials_between_line:not(:empty)::after {
  margin-left: 2em;
}

@media (max-width: 1670px) {
  .testimonials .testimonials_between_line::before, .testimonials_between_line::after {
    margin-right: 50px;
    margin-left: 50px;
  }
  .testimonials .testimonials_between_line:not(:empty)::before {
    margin-right: 1.25em;
  }
  .testimonials .testimonials_between_line:not(:empty)::after {
    margin-left: 1.25em;
  }
}

@media (max-width: 625px) {
  .testimonials .testimonials_between_line::before, .testimonials_between_line::after {
    margin-right: 0px;
    margin-left: 0px;
  }
  .testimonials .testimonials_between_line:not(:empty)::before {
    margin-right: 0.50em;
  }
  .testimonials .testimonials_between_line:not(:empty)::after {
    margin-left: 0.25em;
  }
}

.testimonials .testimonials_quote {
  color: var(--azul);
  font-size: 30px;
  font-weight: bold;
}

.testimonials .testimonials_quote_end {
  color: var(--azul);
  font-size: 30px;
  font-weight: bold;
  position: fixed;
  margin-top: -5px;
}

.testimonials .testimonials_img {
  width: 90px;
  height: auto;
  margin-left: 60%;
}

@media (max-width: 1600px){
  .testimonials .testimonials_img {
    margin-left: 50%;
  }
}

@media (max-width: 1200px){
  .testimonials .testimonials_img {
    margin-left: 35%;
  }
}

@media (max-width: 950px){
  .testimonials .testimonials_img {
    margin-left: 35%;
  }
}

@media (max-width: 767px){
  .testimonials .testimonials_img {
    margin-left: 30%;
  }
}

@media (max-width: 755px){
  .testimonials .testimonials_img {
    margin-left: 50%;
  }
}

@media (max-width: 500px){
  .testimonials .testimonials_img {
    margin-left: 25%;
  }
}

@media (max-width: 375px){
  .testimonials .testimonials_img {
    margin-left: 10%;
  }
}

.testimonials .testimonials_slider {
  margin-left: 15%;
  margin-right: 15%;
}

@media (max-width: 768px){
  .testimonials .testimonials_slider {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 414px){
  .testimonials .testimonials_slider {
    margin-left: 11%;
    margin-right: 12%;
  }
}

@media (max-width: 375px){
  .testimonials .testimonials_slider {
    margin-left: 13%;
    margin-right: 13%;
  }
}

.testimonials .testimonials_numbers {
  margin-left: 100px;
  margin-right: 100px;
}

@media (max-width: 1200px){
  .testimonials .testimonials_numbers {
    margin-left: 70px;
    margin-right: 70px;
  }
}

@media (max-width: 991px){
  .testimonials .testimonials_numbers {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.testimonials .testimonials_numbers_mobile {
  display: none;
}

@media (max-width: 768px) {
  .testimonials .testimonials_numbers {
    display: none;
  }
  .testimonials .testimonials_numbers_mobile {
    display: inline-flex;
  }
}

.testimonials .landing_profile_header_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
}

.testimonials .landing_profile_header_subtitle_experience {
  font-family:'Campton', Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.testimonials .landing_profile_header_experience {
  height: 100vh;
}

@media (max-width: 1024px) {
  .testimonials .landing_profile_header_experience {
    height: 100%;
  }
}

.testimonials .landing_profile_header_input {
  border: none;
  border-radius: 50px;
  outline: none;
  font-size: 18px;
}

.testimonials .landing_profile_header_input_group {
  border: none;
  border-radius: 0px 50px 50px 0px !important;
  background: var(--blanco);
  outline: none;
}

.testimonials .landing_profile_header_img_cards {
  max-width: 100px;
  margin: auto;
}

.testimonials .landing_profile_header_img_profile {
  margin-left: 60%;
}

// ABOUT US

.aboutus {
  width: auto;
  background-color: var(--azul_rgba);
}

.aboutus .aboutus_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.aboutus .aboutus_subtitle {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  color: var(--gris_oscuro);
}

.aboutus .aboutus_img {
  width: 500px;
  height: auto;
}

@media (max-width: 414px){
  .aboutus .aboutus_img {
    width: 230px;
    height: auto;
  }
}

.aboutus .aboutus_body {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-size: 18px;
}

@media (max-width: 414px){
  .aboutus .aboutus_body {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.aboutus .aboutus_strong {
  font-weight: 900;
}

.aboutus .aboutus_img_team {
  width: 120px;
  height: auto;
}

@media (max-width: 414px) {
  .aboutus .aboutus_img_team {
    width: 100px;
    height: auto;
  }
}

.aboutus .aboutus_linkedin {
  position: absolute;
  top: 215px;
  left: 80px
}

@media (max-width: 1200px) {
  .aboutus .aboutus_linkedin {
    position: absolute;
    top: 215px;
    left: 70px
  }
}

@media (max-width: 1024px) {
  .aboutus .aboutus_linkedin {
    position: absolute;
    top: 220px;
    left: 70px
  }
}

@media (max-width: 991px) {
  .aboutus .aboutus_linkedin {
    position: absolute;
    top: 210px;
    left: 45px;
  }
}

@media (max-width: 768px) {
  .aboutus .aboutus_linkedin {
    position: absolute;
    top: 210px;
    left: 45px;
  }
}

.aboutus .aboutus_team_mobile {
  display: none;
}

@media (max-width: 767px) {
  .aboutus .aboutus_team {
    display: none;
  }
  .aboutus .aboutus_team_mobile {
    display: inline;
  }
  .aboutus .aboutus_linkedin {
    position: static;
    top: 0px;
    left: 0px;
  }
}

// SPONSOR

.sponsor {
  width: auto;
  background-color: rgba(var(--gris_oscuro_rgba), 0.9);
  background-image: url("../images/etmmeet/landing/sponsor.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.sponsor .sponsor_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.sponsor .sponsor_hr {
  background-color: var(--blanco); 
  height: 1px; 
  border: 0;
  margin-left: 10%;
  margin-right: 10%;
}

.sponsor .sponsor_slider {
  margin-left: 7%;
  margin-right: 7%;
}

@media (max-width: 414px) {
  .sponsor .sponsor_slider {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.sponsor .sponsor_img_bci { transform: scale(0.5); margin-top: -20px; }
.sponsor .sponsor_img_cla { transform: scale(0.7); margin-top: 0px; }
.sponsor .sponsor_img_ey { transform: scale(0.6); margin-top: -10px; }
.sponsor .sponsor_img_garces { transform: scale(0.5); margin-top: -20px; }
.sponsor .sponsor_img_sbpay { transform: scale(0.6); margin-top: 5px; }
.sponsor .sponsor_img_transbank { transform: scale(0.8); margin-top: 15px; }
.sponsor .sponsor_img_vantrust { transform: scale(0.8); margin-top: 40px; }
.sponsor .sponsor_img_copec { transform: scale(0.7); margin-top: 15px; }
.sponsor .sponsor_img_bci_seguros { transform: scale(1); margin-top: 10px; }
.sponsor .sponsor_img_apanio { transform: scale(0.7); margin-top: 25px; }
.sponsor .sponsor_img_asech { transform: scale(0.8); margin-top: 25px; }
.sponsor .sponsor_img_chile_global { transform: scale(0.8); margin-top: 30px; }
.sponsor .sponsor_img_club_innovacion { transform: scale(0.7); margin-top: 10px; }
.sponsor .sponsor_img_corfo { transform: scale(0.6); margin-top: 10px; }
.sponsor .sponsor_img_cpc { transform: scale(0.5); margin-top: -10px; }
.sponsor .sponsor_img_digevo { transform: scale(0.7); margin-top: 25px; }
.sponsor .sponsor_img_digevo_ventures { transform: scale(0.7); margin-top: 15px; }
.sponsor .sponsor_img_endeavor { transform: scale(0.8); margin-top: 20px; }
.sponsor .sponsor_img_foro_innovacion { transform: scale(0.9); margin-top: 20px; }
.sponsor .sponsor_img_g100 { transform: scale(2); margin-top: 20px; }
.sponsor .sponsor_img_icare { transform: scale(0.5); margin-top: -10px; }
.sponsor .sponsor_img_imagine { transform: scale(0.6); margin-top: 10px; }
.sponsor .sponsor_img_marca_chile { transform: scale(0.4); margin-top: -40px; }
.sponsor .sponsor_img_mi_colab { transform: scale(0.7); margin-top: 20px; }
.sponsor .sponsor_img_mujeres_empresarias { transform: scale(0.8); margin-top: 20px; }
.sponsor .sponsor_img_nnd { transform: scale(0.3); margin-top: -70px; }
.sponsor .sponsor_img_oim { transform: scale(0.6); margin-top: 10px; }
.sponsor .sponsor_img_ovalle_cl { transform: scale(1); margin-top: 30px; }
.sponsor .sponsor_img_people_partners { transform: scale(0.7); margin-top: 10px; }
.sponsor .sponsor_img_prochile { transform: scale(0.7); margin-top: 0px; }
.sponsor .sponsor_img_sna { transform: scale(0.4); margin-top: -50px; }
.sponsor .sponsor_img_startups_chilenas { transform: scale(0.7); margin-top: 10px; }
.sponsor .sponsor_img_chiletec { transform: scale(1.2); margin-top: 20px; }
.sponsor .sponsor_img_chrysalis { transform: scale(1.1); margin-top: 20px; }
.sponsor .sponsor_img_capital_reputacional { transform: scale(0.9); margin-top: -5px; }
.sponsor .sponsor_img_fundacion_chile { transform: scale(0.8); margin-top: 0px; }
.sponsor .sponsor_img_jrconsultores { transform: scale(0.9); margin-top: 30px; }
.sponsor .sponsor_img_mentores_chile { transform: scale(1.5); margin-top: 30px; }
.sponsor .sponsor_img_santiago_innova { transform: scale(0.8); margin-top: 5px; }
.sponsor .sponsor_img_startup_chile { transform: scale(0.9); margin-top: 50px; }
.sponsor .sponsor_img_vendomatica { transform: scale(1); margin-top: 10px; }

@media (max-width: 768px) {
  .sponsor .sponsor_img_cpc { transform: scale(0.5); margin-top: 0px; }
  .sponsor .sponsor_img_icare { transform: scale(0.5); margin-top: 0px; }
  .sponsor .sponsor_img_imagine { transform: scale(0.6); margin-top: 10px; }
  .sponsor .sponsor_img_marca_chile { transform: scale(0.4); margin-top: -20px; }
  .sponsor .sponsor_img_nnd { transform: scale(0.3); margin-top: -40px; }
  .sponsor .sponsor_img_oim { transform: scale(0.6); margin-top: 15px; }
  .sponsor .sponsor_img_people_partners { transform: scale(0.7); margin-top: 20px; }
  .sponsor .sponsor_img_prochile { transform: scale(0.7); margin-top: 10px; }
  .sponsor .sponsor_img_sna { transform: scale(0.4); margin-top: -20px; }
  .sponsor .sponsor_img_startups_chilenas { transform: scale(0.7); margin-top: 15px; }
  .sponsor .sponsor_img_bci_seguros { transform: scale(1); margin-top: 0px; }
}

@media (max-width: 375px) {
  .sponsor .sponsor_img_apanio { transform: scale(0.7); margin-top: 40px; }
  .sponsor .sponsor_img_asech { transform: scale(0.8); margin-top: 40px; }
  .sponsor .sponsor_img_chile_global { transform: scale(0.8); margin-top: 50px; }
  .sponsor .sponsor_img_club_innovacion { transform: scale(0.7); margin-top: 35px; }
  .sponsor .sponsor_img_corfo { transform: scale(0.6); margin-top: 30px; }
  .sponsor .sponsor_img_cpc { transform: scale(0.5); margin-top: 15px; }
  .sponsor .sponsor_img_digevo { transform: scale(0.7); margin-top: 45px; }
  .sponsor .sponsor_img_digevo_ventures { transform: scale(0.7); margin-top: 35px; }
  .sponsor .sponsor_img_endeavor { transform: scale(0.8); margin-top: 40px; }
  .sponsor .sponsor_img_foro_innovacion { transform: scale(0.9); margin-top: 35px; }
  .sponsor .sponsor_img_g100 { transform: scale(2); margin-top: 40px; }
  .sponsor .sponsor_img_icare { transform: scale(0.5); margin-top: 15px; }
  .sponsor .sponsor_img_imagine { transform: scale(0.6); margin-top: 30px; }
  .sponsor .sponsor_img_marca_chile { transform: scale(0.4); margin-top: -10px; }
  .sponsor .sponsor_img_mi_colab { transform: scale(0.7); margin-top: 40px; }
  .sponsor .sponsor_img_mujeres_empresarias { transform: scale(0.8); margin-top: 35px; }
  .sponsor .sponsor_img_nnd { transform: scale(0.3); margin-top: -30px; }
  .sponsor .sponsor_img_oim { transform: scale(0.6); margin-top: 30px; }
  .sponsor .sponsor_img_ovalle_cl { transform: scale(1); margin-top: 45px; }
  .sponsor .sponsor_img_people_partners { transform: scale(0.7); margin-top: 35px; }
  .sponsor .sponsor_img_prochile { transform: scale(0.7); margin-top: 20px; }
  .sponsor .sponsor_img_sna { transform: scale(0.4); margin-top: -15px; }
  .sponsor .sponsor_img_startups_chilenas { transform: scale(0.7); margin-top: 30px; }
  .sponsor .sponsor_img_chiletec { transform: scale(1.2); margin-top: 45px; }
  .sponsor .sponsor_img_chrysalis { transform: scale(1.1); margin-top: 40px; }
  .sponsor .sponsor_img_capital_reputacional { transform: scale(0.9); margin-top: 20px; }
  .sponsor .sponsor_img_fundacion_chile { transform: scale(0.8); margin-top: 25px; }
  .sponsor .sponsor_img_jrconsultores { transform: scale(0.9); margin-top: 45px; }
  .sponsor .sponsor_img_mentores_chile { transform: scale(1.6); margin-top: 45px; }
  .sponsor .sponsor_img_santiago_innova { transform: scale(0.8); margin-top: 20px; }
  .sponsor .sponsor_img_startup_chile { transform: scale(1); margin-top: 55px; }
  .sponsor .sponsor_img_vendomatica { transform: scale(1); margin-top: 30px; }
}

// CONTACT

.contact {
  width: auto;
  background-color: rgba(var(--gris_claro_rgba), 0.6);
  background-image: url("../images/etmmeet/landing/contact.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.contact .contact_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
}

.contact .contact_subtitle {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--negro_rgba);
}

.contact .contact_alert {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
}

.contact .contact_input {
  border: none;
  border-radius: 0px;
  border-bottom:1px solid var(--azul);
  background: transparent;
  outline: none;
}

.contact .contact_input[type="text"], .contact .contact_input[type="email"] {
  background: transparent;
}

.contact textarea{
  background: transparent !important;
}

.contact .contact_btn{
  padding-left: 80px;
  padding-right: 80px;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-size: 18px;
}

// FOOTER

.footer_landing {
  background-color: var(--negro_rgba);
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.footer_landing .footer_landing_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.footer_landing .footer_landing_subtitle {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

@media (max-width: 787px) {
  .footer_landing .footer_landing_subtitle {
    font-size: 15px;
  } 
}

@media (max-width: 414px) {
  .footer_landing .footer_landing_subtitle {
    font-size: 18px;
  } 
}

.footer_landing .footer_landing_img {
  width: 160px;
  height: auto;
}

@media (max-width: 1520px) {
  .footer_landing .footer_landing_img {
    width: 140px;
    height: auto;
  }  
}

@media (max-width: 1200px) {
  .footer_landing .footer_landing_img {
    width: 130px;
    height: auto;
  }  
}

@media (max-width: 991px) {
  .footer_landing .footer_landing_img {
    width: 110px;
    height: auto;
  }  
}

@media (max-width: 767px) {
  .footer_landing .footer_landing_img {
    width: 130px;
    height: auto;
  }  
}

@media (max-width: 414px) {
  .footer_landing .footer_landing_img {
    width: 120px;
    height: auto;
  }  
}

//  LANDING PROFILE

.landing_profile_header {
  // height: 100vh;
  width: auto;
  background-color: rgba(var(--gris_claro_rgba), 0.6);
  background-image: url("../images/etmmeet/landing/testimonials.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.landing_profile_header .landing_profile_header_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
}

.landing_profile_header .landing_profile_header_subtitle {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight:500;
  color: var(--gris_oscuro);
}

.landing_profile_header .landing_profile_header_img {
  width: 300px;
  height: auto;
}

.landing_profile_header .landing_profile_header_btn{
  font-family:'Campton', Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.landing_profile_header .landing_profile_header_subtitle_experience {
  font-family:'Campton', Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.landing_profile_header .landing_profile_header_input {
  border: none;
  border-radius: 0px;
  border-bottom:1px solid var(--azul);
  background: transparent;
  outline: none;
  font-size: 18px;
}

.landing_profile_header .landing_profile_header_input[type="text"] {
  background: transparent;
  border-bottom:1px solid var(--azul);
}

.landing_profile_header .landing_profile_header_input_group {
  border: none;
  border-radius: 0px;
  border-bottom:1px solid var(--azul);
  background: transparent;
  outline: none;
}

.landing_profile_header .howdoit {
  height: 100vh;
  margin-left: 80px;
  margin-right: 80px;
}

@media (max-width: 1200px){
  .landing_profile_header .howdoit {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media (max-width: 1024px){
  .landing_profile_header .howdoit {
    height: 150vh;
  }
}

@media (max-width: 767px){
  .landing_profile_header .howdoit {
    height: 220vh;
  }
}

@media (max-width: 540px){
  .landing_profile_header .howdoit {
    height: 300vh;
  }
}

.landing_profile_header .howdoit .howdoit_mobile {
  display: none;
}

@media (max-width: 1199px) {
  .landing_profile_header .howdoit .howdoit_full {
    display: none;
  }
  .landing_profile_header .howdoit .howdoit_mobile {
    display: inline-flex;
  }
}

.landing_profile_header_experience_slider {
  margin-left: 0%;
  margin-right: 0%;
}

@media (max-width: 768px){
  .landing_profile_header_experience_slider {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media (max-width: 414px){
  .landing_profile_header_experience_slider {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media (max-width: 375px){
  .landing_profile_header_experience_slider {
    margin-left: 7%;
    margin-right: 7%;
  }
}

.landing_profile_header_experience_slider .landing_profile_header_img_cards {
  max-width: 100px;
  margin: auto;
}

.landing_profile_header_experience_slider .landing_profile_header_img_profile {
  margin-left: 60%;
}

.landing_profile_testimonials {
  height: 65vh;
  width: auto;
  background-color: var(--azul);
}

@media (max-width: 540px) {
  .landing_profile_testimonials {
    height: 70vh;
  } 
}

@media (max-width: 414px) {
  .landing_profile_testimonials {
    height: 80vh;
  } 
}

.landing_profile_testimonials .h5{
  color: var(--blanco);
  font-weight: 100;
}

.landing_profile_testimonials .testimonials_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: var(--blanco);
}

.landing_profile_testimonials .testimonials_subtitle {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  color: var(--blanco);
  font-weight: 100;
}

.landing_profile_testimonials .testimonials_slider {
  margin-left: 15%;
  margin-right: 15%;
}

@media (max-width: 768px){
  .landing_profile_testimonials .testimonials_slider {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 414px){
  .landing_profile_testimonials .testimonials_slider {
    margin-left: 12%;
    margin-right: 12%;
  }
}

@media (max-width: 375px){
  .landing_profile_testimonials .testimonials_slider {
    margin-left: 13%;
    margin-right: 13%;
  }
}

.landing_profile_testimonials .testimonials_quote {
  color: var(--blanco);
  font-size: 30px;
  font-weight: bold;
}

.landing_profile_testimonials .testimonials_quote_end {
  color: var(--blanco);
  font-size: 30px;
  font-weight: bold;
  position: fixed;
  margin-top: -5px;
}

.landing_profile_testimonials .testimonials_img {
  width: 70px;
  height: auto;
  margin-left: 70%;
  border: 3px solid var(--blanco);
  border-radius: 100px;
}

@media (max-width: 1600px){
  .landing_profile_testimonials .testimonials_img {
    margin-left: 60%;
  }
}

@media (max-width: 1200px){
  .landing_profile_testimonials .testimonials_img {
    margin-left: 50%;
  }
}

@media (max-width: 950px){
  .landing_profile_testimonials .testimonials_img {
    margin-left: 30%;
  }
}

@media (max-width: 767px){
  .landing_profile_testimonials .testimonials_img {
    margin-left: 30%;
  }
}

@media (max-width: 755px){
  .landing_profile_testimonials .testimonials_img {
    margin-left: 50%;
  }
}

@media (max-width: 500px){
  .landing_profile_testimonials .testimonials_img {
    margin-left: 25%;
  }
}

@media (max-width: 375px){
  .landing_profile_testimonials .testimonials_img {
    margin-left: 10%;
  }
}

.landing_profile_faq {
  width: auto;
  background-color: rgba(var(--gris_claro_rgba), 0.6);
  background-image: url("../images/etmmeet/landing/testimonials.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.landing_profile_faq .landing_profile_faq_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--azul);
}

.landing_profile_faq .landing_profile_faq_input {
  border: none;
  border-radius: 50px;
  outline: none;
  font-size: 18px;
}

.landing_profile_faq .css-1yb2wzy-control {
  border: none;
  background-color: transparent;
}

.landing_profile_faq .css-g1d714-ValueContainer {
  background-color: var(--blanco);
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 50px 0px 0px 50px;
}

.landing_profile_faq .css-9zvo2j-control {
  background-color: transparent;
  border: none;
}

.landing_profile_faq .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.landing_profile_faq .landing_profile_faq_input_addon {
  background-color: var(--blanco);
  margin-left: -1px;
  border-radius: 0px 50px 50px 0px !important;
  border: none;
}

.landing_profile_faq .landing_profile_faq_img {
  width: 120px;
  height: auto;
  margin-top: -70px;
}

.landing_profile_faq .landing_profile_faq_response_border {
  border: none;
  background-color: rgba(var(--blanco_rgba), 0.7);
  border-radius: 10px;
}

.landing_profile_faq .landing_profile_faq_response_title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: var(--gris_oscuro);
}

.landing_profile_faq .landing_profile_faq_response_subtitle {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: var(--gris_oscuro);
}

.btn_etm_day {
  background-color: var(--blanco) !important;
}

.arrowdown {
  width: 60px;
  height: 20px;
  transform:rotate(90deg);
}

.line {
  width: 210px;
  height: auto;
  position: absolute;
  margin-top: -10px;
}

.line.left {
  left: 0px;
}

.line.right {
  float: right;
  right: 0;
}

.carousel-inner {
  height: 600px;
  overflow-y: hidden;
}

@media (max-width: 824px) {
  .carousel-inner {
    height: 460px;
  }
}

.carousel-inner .row {
  max-height: 300px;
}

@media (max-width: 824px) {
  .carousel-inner .row {
    max-height: 320px;
  }
}

.opinion{
  padding: 0 40px;
}

@media (max-width: 824px) {
  .opinion{
    padding: 0 15px;
  }
}

.quote{
  font-size: 14px;
  color: var(--blanco);
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 700;
}

@media (max-width: 824px) {
  .quote{
    font-size: 9px;
    color: var(--blanco);
    font-family: "Lato", Arial, Helvetica, sans-serif;
    font-weight: 700;
  }
}

.carousel-inner hr {
  border-top: 1px solid var(--cafe_claro);
}

.name{
  font-size: 14px;
  color: var(--cafe_claro);
  font-family: "lato-Black";
  margin-bottom: 0;
}

@media (max-width: 824px) {
  .name{
    font-size: 9px;
    color: var(--cafe_claro);
    font-family: "lato-Black";
    margin-bottom: 0;
  }
}

.position{
  font-size: 10px;
  color: var(--blanco);
  font-family: "Lato-Regular";
  margin-bottom: 0;
}

@media (max-width: 824px) {
  .position{
    font-size: 7px;
    color: var(--blanco);
    font-family: "Lato-Regular";
    margin-bottom: 0;
  }
}

.photo{
  width:100%;
  height: 240px;
  object-fit: cover;
}

@media (max-width: 824px) {
  .photo{
    width:100%;
    height: 320px;
    object-fit: cover;
  }
}

@media (max-width: 824px) {
  .photo2{
    width:100%;
    height: 320px;
    object-fit: cover;
    object-position: 60% 50%;
  }
}

@media (max-width: 824px) {
  .photo3{
    width:100%;
    height: 320px;
    object-fit: cover;
    object-position: 65% 50%;
  }
}

@media (max-width: 824px) {
  .photo4{
    width:100%;
    height: 320px;
    object-fit: cover;
    object-position: 20% 50%;
  }
}

.bluebg{
  height: 240px;
  display: table-cell;
  vertical-align: middle;
  background-color: var(--azul_rgba);
}

@media (max-width: 824px) {
  .bluebg{
    height: 320px;
    display: table-cell;
    vertical-align: middle;
    background-color: var(--azul_rgba);
  }
}

.carousel-indicators {
  position: absolute;
  top: 500px;
  margin-bottom: 0;
}

@media (max-width: 824px) {
  .carousel-indicators {
    position: absolute;
    top: 340px;
    margin-bottom: 0;
  }
}

.carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.carousel .carousel-indicators li.active {
  background-color: var(--azul);
}

.carousel .carousel-control-prev-icon {
  background-image: url('../images/conecTime/design/Flecha.svg');
  position: absolute;
  top: 40%;
  color: white;
  border: none;
  width: 2rem;
  height: 1.5rem;
  text-indent: -10000px;
  margin-top: -16px;
  z-index: 99;
  left: 0;
  transform: rotate(180deg);
}

@media (max-width: 824px) {
  .carousel .carousel-control-prev-icon {
    top: 35%;
    left: -10px;
  }
}

.carousel .carousel-control-next-icon {
  background-image: url('../images/conecTime/design/Flecha.svg');
  position: absolute;
  top: 40%;
  color: white;
  border: none;
  width: 2rem;
  height: 1.5rem;
  text-indent: -10000px;
  margin-top: -16px;
  z-index: 99;
  right: 0;
}

@media (max-width: 824px) {
  .carousel .carousel-control-next-icon {
    top: 35%;
    right: -10px;
  }
}

.alert_message {
  color: var(--azul);
}

.texto_perfiles {
  text-align: center;
  border: 1px solid var(--blanco);
  border-radius: 30px;
  padding: 30px;
  margin-top: -70px;
}

.texto_perfiles h5{
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.texto_perfiles b {
  color: var(--azul);
}

.img_asech {
  margin-top: 20px;
  transform: scale(1);
}

.img_avonni {
  margin-top: 10px;
  transform: scale(1.5);
}

.img_chileglobal {
  margin-top: 30px;
  transform: scale(1);
}

.img_chiletect {
  margin-top: 40px;
  transform: scale(1.5);
}

.img_corfo {
  margin-top: 30px;
  transform: scale(0.8);
}

.img_cpc {
  transform: scale(0.6);
}

.img_ey {
  margin-top: 10px;
  transform: scale(0.8);
}

.img_fundacionchile {
  transform: scale(0.7);
}

.img_g100 {
  margin-top: -40px;
  transform: scale(0.9);
}

.img_icare {
  transform: scale(0.6);
}

.img_prochile {
  transform: scale(0.8);
}

.img_vendomatica {
  height: 150px;
  max-height: 150px;
  width: auto;
}

.img_marcachile {
  margin-top: 30px;
  height: 90px;
  max-height: 90px;
  width: auto;
}

.img_capitalreputacional {
  margin-top: 30px;
  height: 80px;
  max-height: 80px;
  width: auto;
}

.img_stgoinnova {
  margin-top: 30px;
  height: 90px;
  max-height: 90px;
  width: auto;
}

.img_endeavor {
  height: 150px;
  max-height: 150px;
  width: auto;
}

.img_jrconsultores {
  margin-top: 50px;
  transform: scale(1.1);
}

.img_mentoreschile {
  margin-top: 40px;
  transform: scale(1.4);
}

.img_mujeresempresarias {
  margin-top: 40px;
  transform: scale(0.8);
}

.img_peoplepartners {
  margin-top: 40px;
  transform: scale(0.8);
}

.img_startupchile {
  height: 150px;
  max-height: 150px;
  width: auto;
}

.img_foroinnovacion {
  margin-top: 30px;
  transform: scale(1.3);
}

.img_cajalosandes {
  margin-top: 0px;
  transform: scale(1);
}

.img_startupole {
  margin-top: 50px;
  transform: scale(1);
}

.img_digevo {
  margin-top: 30px;
  transform: scale(1);
}

.img_oim {
  margin-top: -10px;
  transform: scale(0.8);
}

.img_clubinnovacion {
  margin-top: 30px;
  transform: scale(0.8);
}

@media (max-width: 688px) {
  .img_oim {
    margin-top: 0px;
    transform: scale(0.8);
  }
  .img_avonni {
    margin-top: 20px;
    transform: scale(1.5);
  }
  .img_startupole {
    margin-top: 40px;
    transform: scale(1);
  }
  .img_cpc {
    margin-top: 10px;
    transform: scale(0.6);
  }
  .img_foroinnovacion {
    margin-top: 30px;
    transform: scale(1.3);
  }
  .img_fundacionchile {
    margin-top: 0px;
    transform: scale(0.7);
  }
  .img_g100 {
    margin-top: -20px;
    transform: scale(0.9);
  }
  .img_icare {
    margin-top: 0px;
    transform: scale(0.6);
  }
  .img_prochile {
    margin-top: 0px;
    transform: scale(0.8);
  }
  .img_vendomatica {
    margin-top: -20px;
    transform: scale(1);
  }
  .img_marcachile {
    margin-top: 10px;
    height: 80px;
    max-height: 80px;
    width: auto;
  }
  .img_capitalreputacional {
    margin-top: 20px;
    height: 60px;
    max-height: 60px;
    width: auto;
  }
  .img_stgoinnova {
    margin-top: 10px;
    height: 80px;
    max-height: 80px;
    width: auto;
  }
  .img_endeavor {
    margin-top: -20px;
    height: 150px;
    max-height: 150px;
    width: auto;
  }
  .img_jrconsultores {
    margin-top: 30px;
    transform: scale(1.1);
  }
  .img_mentoreschile {
    margin-top: 20px;
    transform: scale(1.4);
  }
  .img_mujeresempresarias {
    margin-top: 20px;
    transform: scale(0.8);
  }
  .img_peoplepartners {
    margin-top: 20px;
    transform: scale(0.8);
  }
  .img_startupchile {
    margin-top: -20px;
    height: 150px;
    max-height: 150px;
    width: auto;
  }
}
