/* VARIABLES */
:root {
    --rojo: #ee4b5c;
    --blanco: #fff;
    --blanco_hover: #EFEFEF;
    --azul: #1b9dd9;
    --gris_claro: #a5a5a5;
    --gris: #486B79;
    --gris_oscuro: #474747;
    --bg_negro_opacity: rgba(55, 58, 58, 0.7);
}

html {
    scroll-behavior: smooth
}

.etm_register_bg {
    background-color: var(--bg_negro_opacity);
    height: 100vh;
}

@media (max-width: 1700px) AND (max-height: 600px) {
    .etm_register_bg {
        height: 130vh;
    }
}

@media (max-width: 1024px) AND (max-height: 600px) {
    .etm_register_bg {
        height: 135vh;
    }
}

@media (max-width: 767px) {
    .etm_register_bg {
        height: 150vh;
    }
}

@media (max-width: 500px) {
    .etm_register_bg {
        height: 120vh;
    }
}

@media (max-width: 400px) {
    .etm_register_bg {
        height: 170vh;
    }
}

.etm_login_bg {
    background-color: var(--bg_negro_opacity);
    padding-bottom: 50%;
}

@media (max-width: 1270px) {
    .etm_login_bg {
        background-color: var(--bg_negro_opacity);
        padding-bottom: 70%;
    }
}

@media (max-width: 1700px) AND (max-height: 600px) {
    .etm_login_bg {
        // height: 130vh;
    }
}

// @media (max-width: 1024px) AND (max-height: 600px) {
//     .etm_login_bg {
//         height: 135vh;
//     }
// }

// @media (max-width: 767px) {
//     .etm_login_bg {
//         height: 150vh;
//     }
// }


@media (max-width: 400px) {
    .etm_login_bg {
        // padding-bottom: 10%;
        height: 110vh;
    }
}

.etm_login_input {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid var(--azul) !important;
    background: transparent !important;
    color: var(--blanco) !important;
}

.etm_login_input::placeholder {
    color: var(--gris_claro) !important;
}

.etm_link_white {
    color: var(--blanco);
}

.etm_link_white:hover {
    color: var(--azul);
}

.etm_link_gray {
    color: var(--gris);
}

.etm_link_gray:hover {
    color: var(--azul);
    font-weight: bold;
}

.etm_link_blue {
    color: var(--azul);
}

.etm_link_blue:hover {
    text-decoration: underline;
}

.etm_etm_text_gray {
    color: var(--gris);
}

.created_by_simple {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 0px;
    color: var(--blanco);
}

.created_by_full {
    color: var(--gris_oscuro);
}

@media (max-width: 1270px) {
    .created_by_simple {
        position: fixed;
        margin-left: -100px;
    }
}

@media (max-width: 1024px) AND (max-height: 600px) {
    .created_by_simple {
        position: relative;
        margin-left: -100px;
    }
}

@media (max-width: 700px) {
    .created_by_simple {
        position: relative;
        margin-left: -100px;
    }
}

@media (max-width: 1199px) {
    .etm_logo_hide_mobile {
        display: none;
    }
}

.etm_register_input {
    color: var(--gris);
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid var(--azul) !important;
    background: transparent;
    outline: none;
}

.etm_register_input::placeholder {
    color: var(--gris_claro) !important;
}

.etm_required_asterisco {
    color: var(--azul);
    font-weight: bold;
    font-size: 17px;
}

.bg_onboarding_left {
    height: 100%;
    min-height: 200vh;
    background-color: rgba(var(--gris_claro_rgba), 0.6);
    background-image: url("../images/etmmeet/login/trama-gris.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.bg_onboarding_right {
    height: 100%;
    min-height: 200vh;
    background-color: rgba(var(--gris_claro_rgba), 0.6);
    background-image: url("../images/etmmeet/login/Fondo-azul.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media (max-width: 820px) AND (max-height: 1180px) {
    .bg_onboarding_left {
        min-height: 100vh;
    }
    .bg_onboarding_right {
        min-height: 100vh;
    }
}

@media (max-width: 768px) {
    .bg_onboarding_left {
        min-height: 100vh;
    }
    .bg_onboarding_right {
        min-height: 100vh;
    }
}

.bg_onboarding_blue {
    height: 120%;
    min-height: 100vh;
    background-color: rgba(var(--gris_claro_rgba), 0.6);
    background-image: url("../images/etmmeet/login/Fondo-azul.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.img_onboarding {
    border-radius: 20px;
}

.etm_onboarding_verification_phone_input {
    margin-left: 35.5%;
    --ReactInputVerificationCode-itemWidth: 3.0rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
    --ReactInputVerificationCode-itemSpacing: 1.5rem;
}

@media (max-width: 2600px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 33.3%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.5rem;
    }
}

@media (max-width: 2560px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 33.3%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.5rem;
    }
}

@media (max-width: 2400px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 32%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.5rem;
    }
}

@media (max-width: 2134px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 29%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.5rem;
    }
}

@media (max-width: 1920px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 26%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.5rem;
    }
}

@media (max-width: 1750px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 23%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.5rem;
    }
}

@media (max-width: 1700px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 17%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.5rem;
    }
}

@media (max-width: 1280px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 9%;
        --ReactInputVerificationCode-itemWidth: 3.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.2rem;
    }
}

@media (max-width: 1100px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 9%;
        --ReactInputVerificationCode-itemWidth: 2.5rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 0.8rem;
    }
}

@media (max-width: 1024px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 7%;
        --ReactInputVerificationCode-itemWidth: 3rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 0.5rem;
    }
}

@media (max-width: 960px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 13%;
        --ReactInputVerificationCode-itemWidth: 2.5rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 0.8rem;
    }
}

@media (max-width: 820px) AND (max-height: 1180px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 20%!important;
        --ReactInputVerificationCode-itemWidth: 3.0rem!important;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 2rem!important;
    }
}

@media (max-width: 820px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 0%;
        --ReactInputVerificationCode-itemWidth: 2.0rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 0.2rem;
    }
}

@media (max-width: 768px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 25%;
        --ReactInputVerificationCode-itemWidth: 3rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 0.2rem;
    }
}

@media (max-width: 600px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 20%!important;
        --ReactInputVerificationCode-itemWidth: 3rem!important;
        --ReactInputVerificationCode-itemHeight: 3.5rem!important;
        --ReactInputVerificationCode-itemSpacing: 0.2rem!important;
    }
}

@media (max-width: 500px) {
    .etm_onboarding_verification_phone_input {
        margin-left: 2%;
        --ReactInputVerificationCode-itemWidth: 3rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
        --ReactInputVerificationCode-itemSpacing: 0.2rem;
    }
}

.etm_btn_white {
    background-color: var(--blanco) !important;
    padding: 10px 150px 10px 150px !important;
    border: none;
    border-radius: 30px;
    font-size: 20px;
    color: var(--gris_oscuro);
  }
  
  .etm_btn_white:hover {
    background-color: var(--blanco_hover) !important;
  }

  .etm_onboarding_bg_step_number {
    background-color: var(--azul);
    padding: 5px 14px 5px 14px;
    border-radius: 30px;
    color: var(--blanco);
}

@media (max-width: 500px) {
    .etm_onboarding_bg_step_number {
        padding: 1px 8px 1px 8px;
    }
}